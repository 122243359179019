<template>
  <div>
    <div v-if="orderType == 1">
      <div class="resultPage" v-if="payStatus == 'success'">
        <img src="../../assets/signSuccess.png" />
        <span class="title">会员缴费成功</span>

        <span class="desc">您可以缴费记录中查看详情</span>
        <!-- <Button class="mar-t-40" color="#2b72ff" block @click="makeOutInvoice1"
          >开具发票</Button
        > -->
        <Button color="#2b72ff" plain block @click="goMyPage(1)" class="mar-t-40"
          >返回我的</Button
        >
      </div>
      <div class="resultPage failed" v-else>
        <img src="../../assets/signFailed.png" />
        <span class="title">会员缴费失败</span>

        <span class="desc">请重新提交缴费</span>
        <Button class="mar-t-40" color="#2b72ff" block @click="submitAgain1"
          >重试</Button
        >
        <Button color="#2b72ff" plain block @click="goMyPage" class="mar-t-15"
          >返回我的</Button
        >
      </div>
    </div>
    <div v-else>
      <div class="resultPage" :class="money==0?'failed':''" v-if="payStatus == 'success'">
        <img src="../../assets/signSuccess.png" />
        <span class="title">报名缴费成功</span>

        <span class="desc">您可以在我的报名记录中查看详情</span>
        <Button v-if='money>0' class="mar-t-40" color="#2b72ff" block @click="makeOutInvoice"
          >开具发票</Button
        >
        <Button
          color="#2b72ff"
          plain
          block
          @click="viewEnrollment"
          class="mar-t-15"
          >查看报名</Button
        >
      </div>
      <div class="resultPage failed" v-else>
        <img src="../../assets/signFailed.png" />
        <span class="title">报名缴费有误</span>

        <span class="desc">请重新提交报名</span>
        <Button class="mar-t-40" color="#2b72ff" block @click="submitAgain"
          >再次提交</Button
        >
        <Button color="#2b72ff" plain block @click="goListPage" class="mar-t-15"
          >返回培训首页</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import { getPayResult } from "@/api/pay.js";
import { getMemberOrder } from "@/api/member.js";
import config from '@/utils/config.js'

export default {
  name: "signSuccess",
  components: {
    Button,
  },
  data() {
    return {
      orderId: "",
      money: "",
      countNum: "",
      orderType: "", //	0培训 1会员
      payStatus: "",
      meetId: "",
      infos: {},
    };
  },
  created() {
    let { orderId, money } = this.$route.query;
    this.orderId = orderId;
    this.money = money;
    this.countNum = this.$route.query.countNum || "";
    if(money>0){
      this.getPayResult(orderId);
    }else{
      this.orderType='0';
      this.payStatus='success'
    }
  },
  mounted(){
    let mchData={action:"onIframeReady",displayStyle:"SHOW_CUSTOM_PAGE",height:900};
    let postData=JSON.stringify(mchData)
     parent.postMessage(postData, 'https://payapp.weixin.qq.com')
  },
  methods: {
    async getPayResult(merOrderId) {
      let res = await getPayResult({ merOrderId });
      if (res.status == "S") {
        this.orderType = res.data.orderType;
        this.meetId = res.data.meetId;
        if (res.data.payStatus.toUpperCase() == "TRADE_SUCCESS") {
          this.payStatus = "success";
        } else {
          this.payStatus = "fail";
          if (this.orderType == 1) {
            //会员
            this.getMemberOrder(merOrderId);
          }
        }
      }
    },
    //获取支付失败的会员订单的信息
    getMemberOrder(orderId) {
      getMemberOrder({ orderId }).then((res) => {
        let info = res.data;
        if (res.status == "S") {
          let data = {
            id: info.memberType,
            memberAmount: info.paymentAmount,
            memberId: "1FE0GVLKII5A6700A1BB2V3UEKGVC1Q2",
            memberType: info.memberType,
            memberName: info.memberTypeName,
            form: {
              orgs: {
                deptName: info.branchDeptName,
                id: info.branchDept,
              },
              mobile: info.mobile,
              company: info.company,
              name: info.name,
              season: info.season,
            },
          };
          this.infos = data;
        }
      });
    },
    //会员成功
    makeOutInvoice1() {
      // this.$router.push(
      //   `/invoice?type=member&goodId=1111&orderId=${this.orderId}&money=${this.money}`
      // );
      let mchData={action:"jumpOut",jumpOutUrl:config.baseUrl.onlineUrl+`/invoice?type=member&goodId=1111&orderId=${this.orderId}&money=${this.money}`};
      let postData=JSON.stringify(mchData)
      parent.postMessage(postData, 'https://payapp.weixin.qq.com')
    },
    goMyPage(idx) {
      if(idx==1){
      let mchData={action:"jumpOut",jumpOutUrl:config.baseUrl.onlineUrl+`/my`};
      let postData=JSON.stringify(mchData)
      parent.postMessage(postData, 'https://payapp.weixin.qq.com')
      }else{
         this.$router.push("/my");
      }
    },
    //会员失败
    submitAgain1() {
      //this.$router.go(-1);/memberPayFee
      //  let data=Object.assign({},item,{memberId:this.id,memberType:this.memberType})
      console.log(this.infos);
     // this.$router.push({ name: "memberPayFee", params: this.infos });
     this.$router.push( "/memberPayFee?infos="+JSON.stringify(this.infos) );
    },
    //报名成功
    makeOutInvoice() {
      // this.$router.push(
      //   `/invoice?goodsId=0000&orderId=${this.orderId}&countNum=${this.countNum}&money=${this.money}`
      // );
       let mchData={action:"jumpOut",jumpOutUrl:config.baseUrl.onlineUrl+`/invoice?goodsId=0000&orderId=${this.orderId}&countNum=${this.countNum}&money=${this.money}&invoiceMoney=${this.money}`};
      let postData=JSON.stringify(mchData)
      parent.postMessage(postData, 'https://payapp.weixin.qq.com')
    },
    //查看报名
    viewEnrollment() {
      if(this.money==0){
        this.$router.push("/myMeetList");
        return false;
      }
      // this.$router.push("/myMeetList");
       let mchData={action:"jumpOut",jumpOutUrl:config.baseUrl.onlineUrl+`/myMeetList`};
      let postData=JSON.stringify(mchData)
      parent.postMessage(postData, 'https://payapp.weixin.qq.com')
    },
    //报名失败
    submitAgain() {
      //this.$router.go(-1);
      this.$router.push("/meetSignUp?meetId=" + this.meetId);
    },
    goListPage() {
      this.$router.push("/meetList");
    },
  },
};
</script>

<style lang="less" scoped>
@import "./common.less";
.failed{
  height:100vh!important ;
}
</style>
